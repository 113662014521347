import React, { useEffect, useState } from 'react'
import { useCollection } from 'react-firebase-hooks/firestore';
import firebase from '../../core/firebase';
import ReactFullpage from '@fullpage/react-fullpage';
import './home.scss'
import { Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const db = firebase.firestore();
const storage = firebase.storage();

export const Home = () => {
    const books = db.collection('book').orderBy('order')
    const [snapshot, loading, error] = useCollection(books);
    const [pictureList, setPictureList] = useState<string[] | undefined>(undefined);

    useEffect(() => {
        const homeImageRef = storage.ref('homeImages');
        homeImageRef.listAll().then(list => list.items.map(item => 
            item.getDownloadURL().then(url => setPictureList(list => list ? [...list, url] : [url]))
        ))
    }, [])

    return (
        <div >

                {error && <strong>Error: {JSON.stringify(error)}</strong>}
                {loading && <span>Collection: Loading...</span>}
                {snapshot && (
                    <ReactFullpage
                    //fullpage options
                    licenseKey = {'E18389E9-F8D44F7E-861F5302-0A68D704'}
                    // sectionsColor = {['#ffe6bf', '#e3dac8', '#f5ffff', '#8dd7e7']}
                    scrollingSpeed = {1000}
                    verticalCentered = {false}
                    paddingTop = {'56px'}
                    render={({ state, fullpageApi }) => {
                    return (
                        <ReactFullpage.Wrapper>
                            <div key="landing" className="section fp-auto-height-responsive d-flex flex-column flex-fill justify-content-center container">
                                <div className="d-flex flex-row justify-content-center">
                                    <h3 className="text-center">
                                        The Bay Cottage<br/>
                                        <small className="text-muted text-center">
                                        830 Knight Island Road<br/>
                                        Earleville, MD 21919
                                        </small>
                                    </h3>
                                </div>
                                <div className="d-flex flex-row justify-content-center m-4">
                                    {pictureList && 
                                        <Carousel>
                                            {pictureList.map((url, i) => 
                                            <Carousel.Item key={i}>
                                                <img
                                                className="d-block w-100"
                                                src={url}
                                                alt={`Bay House ${i}`}
                                                />
                                            </Carousel.Item>    
                                            )}
                                        </Carousel>
                                    }
                                </div>
                                <div className="d-flex flex-row justify-content-center">

                                    <Button className="mr-2" onClick={() => fullpageApi.moveSectionDown()}>
                                        Read more
                                        <i className="bi bi-arrow-down-short"></i>
                                    </Button>
                                    <Link to={'/calendar'}>
                                        <Button className="ml-2">View Calendar</Button>
                                    </Link>
                                    
                                </div>
                                
                            </div>
                            {snapshot.docs.map(doc => (
                                <div key={doc.id} className="section fp-auto-height-responsive d-flex flex-column flex-fill justify-content-center pr-4">
                                    <div className="container d-flex flex-column p-3" dangerouslySetInnerHTML={{__html: doc.data().text}} />
                                </div>
                            ))}
                        {/* <div className="section">
                            <p>Section 1 (welcome to fullpage.js)</p>
                            <button onClick={() => fullpageApi.moveSectionDown()}>
                            Click me to move down
                            </button>
                        </div>
                        <div className="section">
                            <p>Section 2</p>
                        </div> */}
                        </ReactFullpage.Wrapper>
                    );
                    }}
                />
                )}

        </div>
    )
}
