import React, { useEffect, useState } from "react";
import { useCollectionDataOnce, useDocumentOnce } from "react-firebase-hooks/firestore";
import { useHistory, useParams } from "react-router-dom";
import firebase from '../../core/firebase'
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import 'tinymce/plugins/lists';
import 'tinymce/skins/ui/oxide/skin.min.css';
// import 'tinymce/skins/ui/oxide/content.min.css';
// // import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import './edit-page.scoped.scss'
import { Button, Form } from "react-bootstrap";
import LoadingSpinner from "../../core/loading-spinner";

const db = firebase.firestore();

const EditPage = () => {
    let { id } = useParams<{id: string}>();
    
    const [snapshot, loading, error] = useDocumentOnce(db.doc(`book/${id}`));
    const [text, setText] = useState(snapshot?.data()?.text || '');
    const [pageName, setPageName] = useState(snapshot?.data()?.pageName || '');
    const history = useHistory();
    const [pages] = useCollectionDataOnce(db.collection('book'));
    const [order, setOrder] = useState(0)

    useEffect(() => {
        const pageData = snapshot?.data();
        const pagesLength = (pages?.length) || 0;
        if (pageData && pagesLength) {
            setPageName(pageData.pageName || '');
            setOrder(pageData.order || pagesLength + 1)
        } else {
            setOrder(pagesLength + 1)
        }
    }, [snapshot, pages])

    const goBack = () => {
    history.goBack()
    }

    const handleChange = (a: any, editor: any) => {
        setText(a.target.getContent())
    }

    const handleEditorChange = (a: any, editor: any) => {
        setText(a)
    }

    const submitPage = () => {
        if (!text) {
            setDocument(snapshot?.data()?.text);
        } else {
            setDocument(text);
        }


    }

    const setDocument = (thisText: any) => {
        if (id) {
            db.doc(`book/${id}`).set({
                pageName,
                text: thisText,
                order
            }).then(() => goBack())
        } else {
            db.collection('book').add({
                pageName,
                text: thisText,
                order
            }).then(() => goBack())
        }
    }

    return (
        <div className="container pt-3">
            <div className="row">
                <div className="col">
                {snapshot && 
                    <Form.Group controlId="formBasicEmail">
                        {/* <Form.Label>Page Name</Form.Label> */}
                        <Form.Control value={pageName}  onChange={(e) => setPageName(e.target.value)} type="text" placeholder="Page Name" />
                        <Form.Text className="text-muted">
                        Just to keep track of the page.  Not shown.
                        </Form.Text>
                    </Form.Group>
                }
                </div>
            </div>
            <div className="row">
                <div className="col">
                    {error && <p>Something went wrong</p>}
                    {loading && <LoadingSpinner />}
                    {snapshot &&  <Editor
                        initialValue={snapshot?.data()?.text}
                        init={{
                            skin: false,
                            content_css: false,
                            content_style: "body { font-family: sans-serif;; }",
                            height: 500,
                            menubar: false,
                            plugins: [
                            'link image',
                            'table paste',
                            'lists'
                            ],
                            toolbar:
                            `formatselect | bold italic backcolor |
                            alignleft aligncenter alignright alignjustify | 
                            bullist numlist outdent indent | numlist bullist`
                        }}
                        onChange={handleChange}
                        onEditorChange={handleEditorChange}
                    />}
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Button onClick={submitPage}>Submit</Button>
                </div>
            </div>
        </div>
    )
}

export default EditPage