import moment from "moment";
import React, { useState } from "react";
import { Button, Nav } from "react-bootstrap";
import { useCollection } from "react-firebase-hooks/firestore";
import { LinkContainer } from "react-router-bootstrap";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { AdminContext } from "../../App";
import firebase from '../../core/firebase'
import CalendarDay from "./calendar-day";
import CalendarList from "./calendar-list";

import './calendar.scss'

export const Calendar = () => {
  let { path, url } = useRouteMatch();
  const firestore = firebase.firestore();
  const history = useHistory();

  const [dateObject, setDateObject] = useState(moment());
  const [startSelection, setStartSelection] = useState<moment.Moment | null>(null);
  const [endSelection, setEndSelection] = useState<moment.Moment | null>(null);

  const startDateText = dateObject.clone().startOf('M').toDate()
  const reservationsRef = firestore.collection('reservations').where('startDate', '>=', startDateText);
  const [reservations] = useCollection(reservationsRef)

  const firstDayOfMonth = () => {
    let firstDay = dateObject.clone()
                 .startOf("month")
                 .day();
   return +firstDay;
  };

  const lastDayOfMonth = () => {
    let lastDay = dateObject.clone()
                 .endOf("month")
                 .format("d"); 
   return +lastDay;
  };

  const daysInMonth = () => {
    return dateObject.daysInMonth();
  };

  const isStart = (day: moment.Moment) => {
    if (startSelection) {
      return day.isSame(startSelection, 'D')
    } else {
      return false
    }
  };

  const isEnd = (day: moment.Moment) => {
    if (endSelection) {
      return day.isSame(endSelection, 'D');
    } else {
      return false
    }
  };

  const isInRange = (day: moment.Moment) => {
    if (startSelection && endSelection) {
      if (day) {
        return day.isSameOrAfter(startSelection, 'D') && day.isSameOrBefore(endSelection, 'D')
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const isAfterToday = (day: moment.Moment) => {
    return day.isAfter(moment(), 'D')
  }

  const currentMonthYear = () => {
    return dateObject.format('MMM YYYY')
  };

  const onDayClick = (e: React.MouseEvent, d: moment.Moment) => {
    if (startSelection && !endSelection) {
      const isOverlap = reservations?.docs.map(res => 
        // @ts-ignore
          startSelection.isBefore(moment(res.data().startDate.toDate()), 'day') &&
          d.isAfter(moment(res.data().endDate.toDate()), 'day')
        ).includes(true);
      const isBefore = d.isBefore(startSelection, 'D');
      if (isOverlap || isBefore) {
        setStartSelection(d)
      } else {
        setEndSelection(d)
      }

    } else if (endSelection) {
      setStartSelection(d);
      setEndSelection(null);
    } else {
      setStartSelection(d);
    }

  };

  const onPriorMonth = () => {
    setDateObject(dateObject.clone().subtract(1, 'M'))
  };

  const onNextMonth = () => {
    setDateObject(dateObject.clone().add(1, 'M'))
  };

  const submitRequest = () => {
    
    const start = startSelection?.format('YYYY-MM-DD').toString() || '';
    const end = endSelection?.format('YYYY-MM-DD').toString() || '';
    history.push(`/request/${start}/${end}`)
  };

  const wrappedMonth = () => {

    let blanks: null[] = [];
    for (let i = 0; i < firstDayOfMonth(); i++) {
      blanks.push(null);
    }
    

    let endBlanks: null[] = [];
    for (let i = 6; i > lastDayOfMonth(); i--) {
      endBlanks.push(null);
    }

    let daysMonth: moment.Moment[] = [];
    for (let d = 1; d <= daysInMonth(); d++) {
      const cellDay = dateObject.clone().set('D', d)
      daysMonth.push(cellDay)
    }

    const totalSlots = [...blanks, ...daysMonth, ...endBlanks] ;
    let rows: any = [];
    let cells: any[] = [];

    totalSlots.forEach((cell, i) => {
      if (i % 7 !== 0) {
        cells.push(cell); // if index not equal 7 that means not go to next week
      } else {
        if (i !== 0) {
          rows.push(cells); // when reach next week we contain all td in last week to rows 
          cells = []; // empty container 
        }
        cells.push(cell); // in current loop we still push current row to new container
      }
      if (i === totalSlots.length - 1) { // when end loop we add remain date
        rows.push(cells);
      }
    });

    return rows

    // return rows.map((row: any, i: number) => (
    //   <tr key={'row ' + i} className="calendar-row">
    //     {row}
    //   </tr>
    // ))
  };
      
  return (
    <div className="container-md table-container p-2">
      {reservations && 
        <>
          <AdminContext.Consumer>
            {isAdmin => isAdmin && 
              <div className="row">
                <Nav className="ml-auto mr-3 mb-3" variant="pills" defaultActiveKey="/calendar">
                  <Nav.Item>
                    <LinkContainer exact={true} to={`${url}`}>
                      <Nav.Link>
                        <i className="bi bi-calendar"></i>
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                  <Nav.Item>
                    <LinkContainer exact={true} to={`${url}/list`}>
                      <Nav.Link eventKey="link-1">
                        <i className="bi bi-view-list"></i>
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                </Nav>
              </div>
            }
          </AdminContext.Consumer>
          <Switch>
            <Route exact path={path}>
            <div className="d-flex flex-row justify-content-center mb-3 mt-1">
              <h4 className="text-muted text-center">Select a start and end date to request a reservation</h4>
            </div>
              <div className="row text-center">
              <div className="col">
                <button className="btn" onClick={() => onPriorMonth()}>
                  <i className="bi bi-arrow-left"></i>
                </button>
              </div>
              <div className="col">
                <h4>{currentMonthYear() }</h4>
              </div>
              <div className="col">
                <button className="btn" onClick={() => onNextMonth()}>
                  <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <table>
                  <tbody>
                    {wrappedMonth().map((row: (moment.Moment | null)[], i: number) => (
                      <tr key={'row ' + i} className="calendar-row">
                        {row.map((cal, cellI )=> (
                          <CalendarDay
                            key={'cell' + cellI}
                            d={cal}
                            isStart={cal ? isStart(cal) : false}
                            isEnd={cal ? isEnd(cal) : false}
                            isInRange={cal ? isInRange(cal) : false}
                            isAfterToday={cal ? isAfterToday(cal) : false}
                            reservations={reservations.docs}
                            onDayClick={onDayClick}
                          />
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="d-flex mt-3">
              <Button type="button" className="btn btn-primary" disabled={!startSelection || !endSelection} onClick={() => submitRequest()} block>Request Dates</Button>
            </div>
            </Route>
            <Route exact path={`${path}/list`}>
              <CalendarList></CalendarList>
            </Route>
          </Switch>

        </>
      }
    </div>
  )

}


export default Calendar