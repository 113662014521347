import React from "react"
import { Nav } from "react-bootstrap"
import { LinkContainer } from "react-router-bootstrap";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import AdminRoute from "../../core/admin-route";
import ContactAdd from "./contact-add";
import EditPage from "./edit-page";
import { ImagesAdmin } from "./images";
import Invite from "./invite";
import Invites from "./invites";
import Pages from "./pages";
import Request from "./request";
import Requests from "./requests";
import Rules from "./rules";


export const Admin = () => {
    let { path, url } = useRouteMatch();
    return (
        <div className="container d-flex flex-column flex-fill mt-3">
            <Nav variant="tabs" defaultActiveKey="/home">
                <Nav.Item>
                    <LinkContainer exact={true} to={`${url}`}>
                        <Nav.Link>Book</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`${url}/requests`}>
                        <Nav.Link>Requests</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`${url}/invites`}>
                        <Nav.Link>Invites</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`${url}/rules`}>
                        <Nav.Link>Rules</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
                <Nav.Item>
                    <LinkContainer to={`${url}/images`}>
                        <Nav.Link>Img</Nav.Link>
                    </LinkContainer>
                </Nav.Item>
            </Nav>
            <Switch>
                <Route exact path={path}>
                    <AdminRoute component={Pages} />
                </Route>
                <Route exact path={`${path}/invites/add`}>
                    <AdminRoute component={ContactAdd} />
                </Route>
                <Route exact path={`${path}/invites/:id`}>
                    <AdminRoute component={Invite} />
                </Route>
                <Route exact path={`${path}/invites`}>
                    <AdminRoute component={Invites} />
                </Route>
                <Route exact path={`${path}/book/edit/:id`}>
                    <AdminRoute component={EditPage} />
                </Route>
                <Route exact path={`${path}/book/edit`}>
                    <AdminRoute component={EditPage} />
                </Route>
                <Route exact path={`${path}/requests/:id`}>
                    <AdminRoute component={Request} />
                </Route>
                <Route exact path={`${path}/requests`}>
                    <AdminRoute component={Requests} />
                </Route>
                <Route exact path={`${path}/rules`}>
                    <AdminRoute component={Rules} />
                </Route>
                <Route exact path={`${path}/images`}>
                    <AdminRoute component={ImagesAdmin} />
                </Route>
            </Switch>


        </div>
    )
}