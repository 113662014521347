import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Image, Modal } from 'react-bootstrap';
import { ImageUpload } from './image-upload';
import firebase from '../../core/firebase';
import LoadingSpinner from '../../core/loading-spinner';
const storage = firebase.storage();

const ThumbImage = ({img, i, onDeleted}: {img: firebase.storage.Reference; i: number; onDeleted: Function}) => {
    const [imgUrl, setImgUrl] = useState<string | undefined>(undefined);
    const [show, setShow] = useState(false);
    const [deleting, setDeleting] = useState(false)
    
    useEffect(() => {
        img.getDownloadURL().then(url => setImgUrl(url))
    }, [img])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onDelete = () => {
        setDeleting(true);
        img.delete().then(() => {
            setShow(false);
            onDeleted(i)
        });
        
    }

    return (
        <div className="d-flex m-4">
            <Image src={imgUrl} style={{width: '100px'}} rounded />
            <i onClick={handleShow} className="bi bi-x-circle-fill align-top"/>
            <>
            <Modal show={show} onHide={handleClose}>
                {!deleting && (
                    <>
                        <Modal.Header closeButton>
                            <Modal.Title>Are you Sure?</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Do you want to permanently delete this photo</Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={onDelete}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </>
                )}
                {deleting && 
                    <div className="m-4">
                        <LoadingSpinner />
                    </div>
                }
            </Modal>
            </>
        </div>

    )
}

export const ImagesAdmin = () => {
    const [fileUploads, setFileUploads] = useState<File[] | null>(null);
    const [imageList, setImageList] = useState<firebase.storage.Reference[]  | null>(null)

    useEffect(() => {
        const folderRef = storage.ref('homeImages');
        folderRef.listAll().then(res => setImageList(res.items))
    }, [])

    const onDeleted = (i: number) => {
        setImageList(list => list?.filter((item, li) => li !== i ) || null)
    }

    const onUploaded = (i: number, ref: firebase.storage.Reference) => {
        setFileUploads(uploads => uploads?.filter((upload, ui) => ui !== i) || null);
        setImageList(list => list?.length ? [...list, ref] : [ref])
    }

    const onUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        console.log(e.currentTarget.files);
        const files = Array.from(e.currentTarget.files || []);
        if (files.length) {
            console.log('files')
            setFileUploads(files)
        }
        e.currentTarget.value = '';
    }
    
    return (
        <>
            <Card>
                <Card.Body>
                <Form>
                    <Form.File 
                        id="image-upload"
                        label="Upload New Photos"
                        custom
                        multiple
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onUpload(e)}
                    />
                </Form>
                {fileUploads && fileUploads.map((file, i) => (
                    <div key={file.name} className="mt-3">
                        <ImageUpload  file={file} i={i} onUploaded={onUploaded} />
                    </div>
                ))}
                {imageList && (
                    <div className="d-flex align-content-start flex-wrap">
                        {imageList.map((img, i) => <ThumbImage key={img.name} img={img} i={i} onDeleted={onDeleted}/>)}
                    </div>
                )}
                </Card.Body>
            </Card>
        </>
    )
}