import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from '../../core/firebase'
import { CalendarListItem } from "./calendar-list-item";

const UserReservations = () => {
    
    const [user] = useAuthState(firebase.auth());
    const [reservations, setReservations] = useState<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData> | null>(null);
    const db = firebase.firestore();

    useEffect(() => {
        if (user) {
            const reservationsRef = db.collection('reservations').where('userPhone', '==', user.phoneNumber).orderBy('startDate');
            reservationsRef.get().then(res => setReservations(res))
        }
    }, [user, db]);

    return (
        <div className="container-md table-container p-2">
            {reservations && 
                reservations.docs.map(res => (
                    <CalendarListItem key={res.id} reservation={res} />
                ))
            }
        </div>
    )
}

export default UserReservations;