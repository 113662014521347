import React, { useEffect, useState } from 'react';
import './App.scss';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import { Login } from './components/login'
import { Calendar } from './components/calendar/calendar';
import { Admin } from './components/admin/admin';
import { Home } from './components/home/home';
import PrivateRoute from './core/private-route';
import RequestTime from './components/calendar/request-time';
import { ToastProvider } from 'react-toast-notifications';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import firebase from './core/firebase';
import Reservation from './components/calendar/reservation';
import UserReservations from './components/calendar/user-reservations';
import { GuestBook } from './components/guest-book/guest-book';
import { BookAdd } from './components/guest-book/book-add';

interface Roles {
  admins: string[];
}

export const AdminContext = React.createContext(false)

function App() {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [user] = useAuthState(auth);
  const [roles] = useDocumentDataOnce<Roles>(db.doc('system/roles'));
  const [isAdmin, setisAdmin] = useState(false);

  useEffect(() => {
    if (user && roles) {
      const adminIncludes = roles.admins.includes(user.uid);
      setisAdmin(adminIncludes);
    }
  }, [user, roles])

  return (

    <ToastProvider>
      <AdminContext.Provider value={isAdmin}>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route exact path="/calendar/reservation/:id">
              <PrivateRoute component={Reservation} />
            </Route>
            <Route path="/calendar">
              <PrivateRoute component={Calendar} />
            </Route>
            <Route path="/request/:start/:end">
              <PrivateRoute component={RequestTime} isAdmin={isAdmin} />
            </Route>
            <Route path="/admin">
              <PrivateRoute component={Admin} />
            </Route>
            <Route path="/my-reservations">
              <PrivateRoute component={UserReservations} />
            </Route>
            <Route path="/guest-book/add">
              <PrivateRoute component={BookAdd} />
            </Route>
            <Route path="/guest-book">
              <PrivateRoute component={GuestBook} />
            </Route>
            <Route exact path="/">
              <PrivateRoute component={Home} />
            </Route>
          </Switch>
        </Router>
      </AdminContext.Provider>
    </ToastProvider>

  );
}

export default App;
