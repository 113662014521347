import React, { useState } from 'react'
import { Button, Card, Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import firebase from '../../core/firebase'

export const BookAdd = () => {
    const [fromName, setFromName] = useState('');
    const [message, setMessage] = useState('');
    const [date] = useState(new Date());
    const { addToast } = useToasts();
    const history = useHistory();

    const db = firebase.firestore()

    const submitForm = async (form: React.FormEvent<HTMLFormElement>) => {
        form.preventDefault();
        await db.collection('guestBook').add({
            fromName,
            message,
            date
        })
        addToast('Guest book signed!', {appearance: 'success', autoDismiss: true});
        history.push('/guest-book')
    }

    return (
        <div className="container">
            <Card>
                <Form onSubmit={form => submitForm(form)}>
                    <Card.Header>
                        Add to Guest Book
                    </Card.Header>
                    <Card.Body>
                        <Form.Group controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Enter name" onChange={e => setFromName(e.currentTarget.value)} />
                            <Form.Text className="text-muted">
                                The public name shown in the Guest Book
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formMessage">
                            <Form.Label>Guest Book Meassage</Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={e => setMessage(e.currentTarget.value)}/>
                        </Form.Group>
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="outline-primary" type="submit">Done</Button>
                    </Card.Footer>
                </Form>

            </Card>
        </div>
    )
}