import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import { LinkContainer } from 'react-router-bootstrap'
import { Dropdown } from 'react-bootstrap';

import firebase from '../core/firebase'
import { AdminContext } from '../App';

const Header = () => {
    const auth = firebase.auth();
    const [expanded, setExpanded] = useState(false);

    const logout = () => {
        auth.signOut()
    }
    
    return (
        <Navbar bg="light" expand="lg" fixed="top" expanded={expanded}>
            <Navbar.Brand href="/">Bay Cottage</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={(e) => setExpanded(!expanded)}/>
            <Navbar.Collapse id="basic-navbar-nav mf-auto">
                <Nav className="mr-auto">
                    <LinkContainer to="/" onClick={() => setExpanded(false)}>
                        <Nav.Link>Home</Nav.Link>
                    </LinkContainer>
                    <LinkContainer to="/calendar" onClick={() => setExpanded(false)}>
                        <Nav.Link>Calendar</Nav.Link>
                    </LinkContainer>
                    <AdminContext.Consumer>
                        {isAdmin => !isAdmin && <LinkContainer to="/my-reservations" onClick={() => setExpanded(false)}>
                            <Nav.Link>My Reservations</Nav.Link>
                        </LinkContainer>}
                    </AdminContext.Consumer>
                    <AdminContext.Consumer>
                        {isAdmin => isAdmin && <LinkContainer to="/admin" onClick={() => setExpanded(false)}>
                            <Nav.Link>Admin</Nav.Link>
                        </LinkContainer>}
                    </AdminContext.Consumer>
                    <LinkContainer to="/guest-book" onClick={() => setExpanded(false)}>
                        <Nav.Link>Guest Book</Nav.Link>
                    </LinkContainer>

                </Nav>
                <Dropdown>
                    <Dropdown.Toggle
                    className={expanded ? 'mt-3' : ''}
                     variant="outline-secondary" id="dropdown-basic">
                        <i className="bi-person"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu align={expanded ? 'left' : 'right'}>
                        <Dropdown.Item onClick={logout}>Log out</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Header
