import React, { useEffect, useState } from "react"
import { ProgressBar } from "react-bootstrap";
import firebase from '../../core/firebase';

export const ImageUpload = ({file, i, onUploaded} : {file: File; i: number; onUploaded: Function}) => {
    const storage = firebase.storage();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        console.log('here')
        const path = `homeImages/${Date.now()}_${file.name}`;
        const ref = storage.ref(path);
        const task = ref.put(file);
        task.on('state_changed', 
        (snapshot) => {
            setProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        });
        task.then(img => onUploaded(i, img.ref))
        // return () => {
        //     cleanup
        // }
    }, [file, storage, i, onUploaded])



    return (
        <ProgressBar now={progress} />
    )
}