import moment from "moment";
import React, { useState } from "react";
import { Button, Card, Col, Form, Modal, Row } from "react-bootstrap";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import firebase from '../../core/firebase'
import LoadingSpinner from "../../core/loading-spinner";
import { RequestModel } from "../../types/request";
import ReservationDetails from "./reservation-details";

const Request = () =>  {
  const db = firebase.firestore();
  const { id } = useParams<{id: string}>();
  const requestRef = db.doc(`reservations/${id}`);
  const [request, loading, error] = useDocumentDataOnce<RequestModel>(requestRef);
  const history = useHistory();
  const { addToast } = useToasts();
  const [show, setShow] = useState(false);
  const [denyReason, setDenyReason] = useState('');

  const handleClose = () => setShow(false);

  const onApprove = () => {
    requestRef.update({ approved: true}).then(() => {
      addToast('Request Approved', { appearance: 'success', autoDismiss: true});
      history.push('/admin/requests')
    }).catch(e => console.error(e))
  }

  const onDeny = () => {
    setShow(true)
  }

  const onDenySubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const batch = db.batch();
    const deniedRef = db.collection('deniedReservation').doc();
    batch.set(deniedRef, {
      ...request,
      denyReason
    });
    const loopDate = moment(request?.startDate.toDate())
    while (loopDate.isSameOrBefore(moment(request?.endDate.toDate()), 'day')) {
      const dateId = loopDate.format('MMDDYYYY').toString();
      const takenRef = db.collection('takenDays').doc(dateId);
      batch.delete(takenRef);
      loopDate.add(1, 'day');
    }
    batch.delete(requestRef);
    batch.commit().then(() => {
      addToast('Request Denied', { appearance: 'warning', autoDismiss: true});
      history.push('/admin/requests');
    }).catch(e => console.error(e))
  }

  return (
      <div>
        {loading && <LoadingSpinner />}
        {error && <p>Error</p>}
        {request && 
          <Card className='mod-card'>
            <Card.Body>
              <Card.Title>
              Pending Request
              </Card.Title>
              <ReservationDetails props={request} />

            </Card.Body>
            <Card.Footer>
              <Row>
                <Col>
                  <Button block onClick={onApprove}>Approve</Button>
                </Col>
                <Col>
                  <Button variant="danger" block onClick={onDeny}>Deny</Button>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        }
        <Modal show={show} onHide={handleClose}>
          <Form onSubmit={e => onDenySubmit(e)}>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="reasonWhy">
                  <Form.Label>Please give a brief explanation as to why</Form.Label>
                  <Form.Control as="textarea" rows={3} onChange={e => setDenyReason(e.currentTarget.value)} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" type="button" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="primary" type="submit">
                Save Changes
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
  )
}

export default Request