import React, { useState } from 'react';
import { Button, Card, Form } from "react-bootstrap";
import { useHistory } from 'react-router';
import firebase from '../core/firebase'

interface wProps {
    user: firebase.User
}

export const WelcomeForm = ({user}: wProps) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [validated, setValidated] = useState(false);
    const db = firebase.firestore();
    const history = useHistory();

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!e.currentTarget.checkValidity()) {
            e.stopPropagation();
        } else {
            const userDocRef = db.collection('users').doc(user.uid);
            userDocRef.set({
                name,
                email
            }).then(() => history.push('/'))
        }
        setValidated(true);

    }

    return (
        <div className="container text-center">
            <Card>
                <Card.Body>
                    <h4>Looks like it is your first time</h4>
                    <h5 className="text-muted">We have a few questions</h5>

                    <Form validated={validated} onSubmit={e => onSubmit(e)}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" required onChange={e => setEmail(e.target.value)}/>
                            <Form.Text className="text-muted">
                                To notify when reservation is approved
                            </Form.Text>
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Your Name</Form.Label>
                            <Form.Control type="text" placeholder="Your full name" required onChange={e => setName(e.target.value)} />
                        </Form.Group>
                        <Button type="submit">Submit</Button>
                    </Form>
                </Card.Body>

            </Card>
        </div>

    )
};
