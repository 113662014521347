import { Button, Card } from "react-bootstrap";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useHistory, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import firebase from '../../core/firebase';
import LoadingSpinner from "../../core/loading-spinner";

const Invite = () => {
    let { id } = useParams<{id: string}>();
    const db = firebase.firestore();
    const docRef = db.doc(`invites/${id}`);
    const [invite, loading, error] = useDocumentDataOnce(docRef);
    const {addToast} = useToasts();
    const history = useHistory();

    const formatString = (phoneNumberString: string) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return ['(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    const onDelete = () => {
        docRef.delete().then(() => {
            addToast('Number Deleted', {appearance: 'success', autoDismiss: true});
            history.push('/admin/invites')
        })
    }

    return (
        <div className="d-flex flex-column flex-fill">
            {loading && <LoadingSpinner/>}
            {error && <p>Error</p>}
            {invite && 
                <Card className='mod-card'>
                    <Card.Body>
                        <Card.Title>
                            {invite.name}
                        </Card.Title>
                        {formatString(id)}
                    </Card.Body>
                    <Card.Footer>
                        <Button variant="danger" onClick={onDelete}>Delete</Button>
                    </Card.Footer>
                </Card>
            }
        </div>

    )
}

export default Invite