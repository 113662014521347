import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import moment from "moment";
import firebase from '../../core/firebase'
import { useToasts } from "react-toast-notifications";
import { useCollection, useDocumentDataOnce } from "react-firebase-hooks/firestore";
import LoadingSpinner from "../../core/loading-spinner";
import { Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { UserProfile } from "../../types/userProfile.model";

const RequestTime = ({ isAdmin }: { isAdmin: boolean }) => {
    const db = firebase.firestore();
    const history = useHistory();
    const { start, end } = useParams<{ start: string, end: string}>();
    const [sysValues, loading, error] = useDocumentDataOnce(db.doc('system/values'));
    const [invitees] = useCollection(db.collection('invites'));
    const [ user ] = useAuthState(firebase.auth());
    const [userProfile, setUserProfile] = useState<UserProfile | null>(null)
    const [suggestedCost, setSuggestedCost] = useState(0);
    const startDate = moment(start);
    const endDate = moment(end);
    const days = endDate.diff(startDate, 'days') + 1;
    const [name, setName] = useState('');
    const [pets, setPets] = useState(false);
    const [numberPeople, setNumberPeople] = useState(1);
    const { addToast } = useToasts();
    const [readRules, setReadRules] = useState(false);
    const [show, setShow] = useState(false);
    const rulesForm = useRef<HTMLFormElement>(null);
    const [validated, setValidated] = useState(false);
    const [showError, setShowError] = useState(false);
    const [resFor, setResFor] = useState<string | null>(null)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (sysValues && days) {
            const dayCost = sysValues.dayCost as number;
            const petsFactor = pets ? 1.25 : 1;
            const cost = dayCost * days * petsFactor;
            setSuggestedCost(cost);
        }
    }, [sysValues, pets, days])

    useEffect(() => {
        if (!userProfile) {
            db.collection('users').doc(user?.uid).get().then(profile => setUserProfile(profile?.data() as UserProfile))
        }
    }, [user, userProfile, db])

    useEffect(() => {
        if (userProfile) {
            setName(userProfile.name);
        }
    }, [userProfile])

    const rulesChange = () => {
        if (rulesForm.current) {
            const isValid = rulesForm.current.checkValidity();
            setReadRules(isValid);
        }
    }

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        } else {
            event.preventDefault();
            handleShow();
        }
        setValidated(true);
    }

    // TODO Remove suggested donation
    const onSubmit = async() => {
        const loopDate = startDate.clone();
        try {
            await db.runTransaction(async (t) => {
                const dateDocs: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>[] = [];
                while (loopDate.isSameOrBefore(endDate, 'day')) {
                    const dateId = loopDate.format('MMDDYYYY').toString();
                    const takenRef = db.collection('takenDays').doc(dateId);
                    const takenDoc = await t.get(takenRef);
                    dateDocs.push(takenDoc);
                    loopDate.add(1, 'day');
                }
                const datesTaken = dateDocs.map(doc => doc.exists).includes(true);
                if (datesTaken) {
                    throw Error('Date already taken');
                } else {
                    dateDocs.forEach(doc => {
                        const takenRef = db.collection('takenDays').doc(doc.id);
                        t.set(takenRef, { taken: true });
                    })
                    
                }
                const resRef = db.collection('reservations').doc();
                t.set(resRef, {
                    startDate: startDate.toDate(),
                    endDate: endDate.toDate(),
                    name,
                    pets,
                    numberPeople,
                    approved: isAdmin ? true : false,
                    suggestedCost,
                    userPhone: isAdmin ? resFor : user?.phoneNumber,
                    email: userProfile?.email
                })
            }).then(() => {
                addToast('Request Submitted', {
                    appearance: 'success',
                    autoDismiss: true,
                })
                history.push('/');
            })
        } catch (e) {
            setShow(false);
            setShowError(true);
        }

    }

    return (
        <div className="d-flex flex-fill flex-column">
            {loading && 
                <LoadingSpinner></LoadingSpinner>
            }
            {error && <p>Error</p>}
            {sysValues && 
                <Container className="table-container pt-4">
                    <h5 className="text-center">{startDate.format("ddd MMM Do 'YY").toString()} to {endDate.format("ddd MMM Do 'YY").toString()}</h5>
                    <h5 className="text-center">{days} days and {days - 1} nights</h5>
                    <Row className="pb-3">
                        <Col>
                            <Form noValidate validated={validated} onSubmit={(e) => handleSubmit(e)}>
                                <Row>
                                    <Col sm>
                                    {isAdmin && 
                                        <Form.Group className="mt-3" controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Reservation For</Form.Label>
                                        <Form.Control as="select" onChange={e => setResFor(e.target.value)}>
                                            {invitees?.docs.map(invitee => (
                                                <option value={invitee.id}>{invitee.data().name}</option>
                                            ))}
                                        </Form.Control>
                                        </Form.Group>
                                    }
                                    {!isAdmin &&
                                        <Form.Group controlId="name">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control defaultValue={name} onChange={e => setName(e.target.value)} required type="text" placeholder="Stuffy McBubbles" />
                                        </Form.Group>
                                    }
                                    </Col>
                                    <Col sm>
                                        <Form.Group controlId="resForm.numberPeople">
                                            <Form.Label>Number of People</Form.Label>
                                            <Form.Control onChange={e => setNumberPeople(+e.target.value)} required type="num" placeholder="1" />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Form.Check 
                                    onChange={e => setPets(e.target.checked)} 
                                    type='checkbox'
                                    id='pet-check'
                                    label='Are you bringing pets?'
                                />

                                <Row className="mt-3">
                                    <Col>
                                        <Button type="submit" block>Submit</Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Rules</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form ref={rulesForm} onChange={rulesChange}>
                            {sysValues.rules.map((rule: string, index: number) => 
                                <Form.Check 
                                onChange={e => setPets(e.target.checked)} 
                                type='checkbox'
                                id={'rule-' + index.toString()}
                                key={'rule-' + index.toString()}
                                label={rule}
                                required
                            />
                            )}
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={onSubmit} disabled={!readRules}>Understood</Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={showError}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                    <Modal.Title>Uh Oh</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="d-flex flex-row">
                            <div className="d-flex flex-column justify-content-center">
                                <h1 className="text-danger">
                                    <i className="bi bi-exclamation-triangle-fill"></i>
                                </h1>
                            </div>
                            <p className="pl-4">Someone has beaten you to the punch and these days are now reserved.  Please try again</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Link to="/calendar">
                        <Button variant="primary">See Calendar</Button>
                    </Link>
                    </Modal.Footer>
                </Modal>
                </Container>
            }

        </div>
    )
}

export default RequestTime
