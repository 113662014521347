import React from "react"
import { Spinner } from "react-bootstrap"

const LoadingSpinner = () => {
    return (<div className="d-flex flex-fill flex-column align-items-center justify-content-center">
        <Spinner animation="border" variant="secondary" role="status">
            <span className="sr-only">Loading...</span>
        </Spinner>
    </div>)
}

export default LoadingSpinner