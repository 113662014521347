
// import AuthService from './Services/AuthService'
import { Route } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from './firebase'

import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import React from 'react';
import { Home } from '../components/home/home';
import LoadingSpinner from './loading-spinner';

interface IProps {
  component: any;
}

interface Roles {
    admins: string[];
}


const AdminRoute = ({ component: Component, ...rest }: IProps) => {

  const auth = firebase.auth();
  const db = firebase.firestore();
  const [user, loading, error] = useAuthState(auth);
  const [roles, rolesLoading, rolesError] = useDocumentDataOnce<Roles>(db.doc('system/roles'))
  let isAdmin = false
  if (user && roles) {
    isAdmin = roles.admins.includes(user.uid)
  }

  if (user && roles) {
    return (
        <Route
          {...rest}
          render={props =>
            isAdmin ? (
              <div className="d-flex flex-fill flex-column">
                <Component {...props} />
              </div>
            ) : (
              <Home></Home>
              // <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
          }
        />
      )
  } else if (error || rolesError) {
    console.error(error);
    return (<p>error</p>)
  } else if (loading || rolesLoading) {
    return (<LoadingSpinner />)
  } else {
    return <p>Unknown</p>
  }

}

export default AdminRoute