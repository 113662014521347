import React, { useState } from "react"
import { Button, Card, Form } from "react-bootstrap";
import { useHistory } from "react-router";
import { useToasts } from "react-toast-notifications";
import firebase from '../../core/firebase'

const ContactAdd = () => {
    const [phone, setPhone] = useState<string | null>(null);
    const [name, setName] = useState('');
    const [validation, setValidation] = useState(false)
    const db = firebase.firestore();
    const { addToast } = useToasts();
    const history = useHistory();

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity()) {
            // e.stopPropagation();
            const docRef = db.collection('invites').doc(`+1${phone}`);
            db.runTransaction(t => {
                return t.get(docRef).then(doc => {
                    if (doc.exists) {
                        throw Error('Number already exists')
                    } else {
                        t.set(docRef, { name })
                    }
                })
            }).then(() => {
                addToast('Number Added!', { appearance: 'success', autoDismiss: true });
                history.push('/admin/invites');
            })
                .catch(e => addToast(e.message, {
                appearance: 'error',
                autoDismiss: true,
            }));
        } else {
            setValidation(true)
        }

    }

    return (
        <Card className="mod-card">
            <Form validated={validation} onSubmit={e => submit(e)}>
                <Card.Body>
                    <Form.Group controlId="phone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="tel" placeholder="7175551111" required minLength={10} onChange={e => setPhone(e.target.value)} />
                        <Form.Text className="text-muted">
                        Only enter numbers with area code
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="phone">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Seymour Butts" onChange={e => setName(e.target.value)} />
                    </Form.Group>
                </Card.Body>
                <Card.Footer>
                    <Button variant="primary" type="submit" >
                        Submit
                    </Button>
                </Card.Footer>
            </Form>
        </Card>
    )
}

export default ContactAdd