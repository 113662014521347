import React, { useEffect, useState } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import { useDocumentData } from "react-firebase-hooks/firestore";
import firebase from '../../core/firebase'
import LoadingSpinner from "../../core/loading-spinner";

const Rules = () => {
    const db = firebase.firestore();
    const [valuesData, loading, error] = useDocumentData(db.doc('system/values'));
    const [rules, setRules] = useState([]);
    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editedRule, setEditedRule] = useState<string | null>(null);
    const [addRule, setAddRule] = useState(false);
    const [newRule, setNewRule] = useState('')

    useEffect(() => {
        const rulesData = valuesData?.rules;
        if (rulesData) {
            setRules(rulesData)
        }
        
    }, [valuesData])

    const saveRules = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (editedRule && editIndex !== null) {
            const newRules = rules.map((rule, i) => i === editIndex ? editedRule : rule)
            db.doc('system/values').update({rules: newRules}).then(() => console.log('updated'))
        }
        setEditIndex(null);
    }

    const deleteRule = () => {
        const newRules = rules.filter((rule, i) => i!== editIndex);
        db.doc('system/values').update({rules: newRules}).then(() => console.log('updated'));
        setEditIndex(null);
    }

    const onAddRule = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const newRules = [...rules, newRule]
        db.doc('system/values').update({rules: newRules}).then(() => console.log('updated'));
        setAddRule(false);
    }

    const addRuleForm = () => {
        return (
            <ListGroup.Item className="d-flex flex-row">
                <Form className="d-flex flex-grow-1 flex-row" onSubmit={(e) => onAddRule(e)}>
                    <div className="d-flex flex-column flex-grow-1">
                        <Form.Group  controlId="newRule">
                            <Form.Label>New Rule</Form.Label>
                            <Form.Control as="textarea" onChange={(e) => setNewRule(e.target.value)}/>
                        </Form.Group>
                    </div>
                    <div className="d-flex flex-column justify-content-center ml-2">
                        <Button type="submit">
                            <i className="bi bi-check2"></i>
                        </Button>
                    </div>
                </Form>
            </ListGroup.Item>
        )
    }

    const listRule = (rule: string, i: number) => {
        return (
            <ListGroup.Item key={i} className="d-flex flex-row">
                <div className="d-flex flex-grow-1">{rule}</div>
                <div className="d-flex flex-column justify-content-center ml-2 text-primary">
                    <i className="bi bi-pen-fill" onClick={() => setEditIndex(i)}></i>
                </div>
            </ListGroup.Item>
        )
    }

    const editRule = (rule: string, i: number) => {
        return (
            <ListGroup.Item key={i} className="d-flex flex-row">
                <Form className="d-flex flex-grow-1 flex-row" onSubmit={(e) => saveRules(e)}>
                    <div className="d-flex flex-column flex-grow-1">
                        <Form.Group  controlId="exampleForm.ControlTextarea1">
                            <Form.Label>Edit Rule</Form.Label>
                            <Form.Control as="textarea" defaultValue={rule} onChange={(e) => setEditedRule(e.target.value)}/>
                        </Form.Group>
                    </div>
                    <div className="d-flex flex-column justify-content-center ml-2">
                        <Button type="submit">
                            <i className="bi bi-check2"></i>
                        </Button>
                        <Button className="mt-2" type="button" variant="danger" onClick={deleteRule}>
                            <i className="bi bi-trash-fill"></i>
                        </Button>
                    </div>
                </Form>
            </ListGroup.Item>
        )
    }

    return (
        <div>
            {loading && <LoadingSpinner />}
            {error && <p>Error</p>}
            {valuesData && 
                <ListGroup variant="flush">
                    <ListGroup.Item className="d-flex flex-row">
                        <Button onClick={() => setAddRule(true)}>Add Rule</Button>
                    </ListGroup.Item>
                    {addRule && addRuleForm()}
                    {rules.map((rule, i) => {
                        if (i === editIndex) {
                            return editRule(rule, i)
                        } else {
                            return listRule(rule, i)
                        }
                    })}
                </ListGroup>
            }
        </div>
    )
}

export default Rules