
// import AuthService from './Services/AuthService'
import { Redirect, Route } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from './firebase'

import Header from '../components/header'

interface IProps {
  component: any;
  isAdmin?: boolean;
}


const PrivateRoute = ({ component: Component, ...rest }: IProps) => {

  const auth = firebase.auth();
  const [user, loading, error] = useAuthState(auth);
  const isLoggedIn = Boolean(user) 

  if (!loading) {
    return (
        <Route
          
          render={props =>
            isLoggedIn ? (
              <>
                <Header />
                <div className={props.location.pathname !== '/' ? 'd-flex flex-column flex-fill toolbar-padding' : ''}>
                  <Component {...props} {...rest} />
                </div>

              </>
            ) : (
              <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
          }
        />
      )
  } else if (error) {
    console.error(error);
    return (<p>error</p>)
  } else {
    return (<p>Loading</p>)
  }

}

export default PrivateRoute