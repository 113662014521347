import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useHistory } from 'react-router';
import firebase from '../../core/firebase';
import LoadingSpinner from '../../core/loading-spinner';
import { CalendarListItem } from './calendar-list-item';

const CalendarList = () => {
    const db = firebase.firestore();
    const reservationRef = db.collection('reservations').orderBy('startDate');
    const [resevations, loading, error] = useCollection(reservationRef);
    const history = useHistory();

    const onView = (id: string) => {
        history.push(`/admin/requests/${id}`)
    }

    return (
        <div>
            {error && <p>Error</p>}
            {loading && <LoadingSpinner/>}
            {resevations && resevations.docs.map(res => (
                <CalendarListItem key={res.id} reservation={res} onView={onView} />
            ))
            }
        </div>
    )
}

export default CalendarList