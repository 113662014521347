import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { AdminContext } from '../../App';
import firebase from '../../core/firebase'

interface Props {
    d: moment.Moment | null;
    isStart: boolean;
    isEnd: boolean;
    isInRange: boolean;
    isAfterToday: boolean;
    reservations: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[];
    onDayClick: any;
}

const CalendarDay = (props: Props) => {
    let sDay = props.isStart ? "selection-start" : "";
    let eDay = props.isEnd ? "selection-end" : "";
    let inRange = props.isInRange ? "selection-in-range" : "";
    const reservation = props.reservations.find(res => props.d?.isSameOrAfter(res.data().startDate.toDate(), 'D') && 
    props.d?.isSameOrBefore(res.data().endDate.toDate(), 'D'));
    let isReservedStart =   props.d?.isSame(reservation?.data().startDate.toDate(), 'D') ? "already-reserved-start" : "";
    let isReservedEnd = props.d?.isSame(reservation?.data().endDate.toDate(), 'D') ? "already-reserved-end" : "";
    let isReserved = reservation ? "already-reserved" : "";
    let history = useHistory();

    const adminNav = () => {
      const url = reservation?.data().approved ? `/calendar/reservation/${reservation?.id}` :
        `/admin/requests/${reservation?.id}`
      history.push(url)
    }

    return (
      <AdminContext.Consumer>
        {isAdmin => 
          <td onClick={isAdmin && reservation ? adminNav : undefined }
            className={`p-0 ${inRange} ${sDay}-range ${eDay}-range ${isReserved} 
              ${isReservedStart} ${isReservedEnd}
              ${isAdmin && reservation && !reservation?.data().approved ? 'pending-color': ''}`}>
            <div
              className={`h-100 w-100 calendar-cell-content ${sDay} ${eDay} ${inRange}-span ${isReserved}-range`}
              onClick={e => {
                if (props.isAfterToday && !isReserved) {
                  props.onDayClick(e, props.d);
                }}}
            >
                <span className={!props.isAfterToday || isReserved ? 'text-muted' :
                  'font-weight-bold'}>{props.d?.format('D')}</span>
            </div>
          </td>

        }
      </AdminContext.Consumer>

    )

}

export default React.memo(CalendarDay)