import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';
import firebase from '../../core/firebase'
import LoadingSpinner from '../../core/loading-spinner';

const DateText = (request: any) => {
    const data = request.prop.data();
    return (
        <ListGroup.Item>
            <Link to={`/admin/requests/${request.prop.id}`}>
            {data.name} - {data.startDate.toDate().toDateString()} to {data.endDate.toDate().toDateString()}
            </Link>
        </ListGroup.Item>
    )
}

const Requests = () => {
    const db = firebase.firestore();
    const requestsRef = db.collection('reservations').where('approved', '==', false);
    const [requests, loading, error] = useCollectionOnce(requestsRef);
    return (
        <div className="d-flex flex-fill flex-column">
            {loading && 
                <LoadingSpinner></LoadingSpinner>
            }
            {error && <p>Error</p>}
            {requests && 
                <div>
                    <ListGroup variant="flush">
                        {requests.docs.length ? requests.docs.map(request => (
                            <DateText key={request.id} prop={request}></DateText>
                        )) : <ListGroup.Item>
                            No Pending Requests
                        </ListGroup.Item>
                    }
                    </ListGroup>
                </div>
            }
        </div>
    )

}

export default Requests