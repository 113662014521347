import React, { useRef } from "react";
import { Button, ListGroup } from "react-bootstrap";
import { useCollection } from "react-firebase-hooks/firestore"
import { Link } from "react-router-dom";
import firebase from '../../core/firebase'
import LoadingSpinner from "../../core/loading-spinner";
import vCard from 'vcf';
import { useToasts } from "react-toast-notifications";

const Invites = () => {
    const db = firebase.firestore();
    const [invites, loading, error] = useCollection(db.collection('invites'));
    const inputFile = useRef<HTMLInputElement>(null);
    const { addToast } = useToasts();

    const onButtonClick = () => {
        // `current` points to the mounted file input element
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const handleFileSelect = async () => {
        const files = inputFile.current?.files;
       if (files) {
           const file = files[0]
           const text = await file.text();
           const cards = vCard.parse(text);
           const batch = db.batch()
           cards.forEach(card => {
               const name = card.data.fn.valueOf().toString();
               const tel = card.data.tel;
               let number = '';
               if (Array.isArray(tel)) {
                   const firstTel = tel[0];
                   const cleanedTel = firstTel.valueOf().toString().replace(/\D/g,'').slice(-10);
                   const formattedTel = `+1${cleanedTel}`;
                   number = formattedTel;
               } else {
                   const cleanedTel = tel.valueOf().toString().replace(/\D/g,'').slice(-10);
                   const formattedTel = `+1${cleanedTel}`;
                   number = formattedTel;
               }
               const ref = db.collection('invites').doc(number);
               batch.set(ref, { name })
           });
           batch.commit().then(() => addToast('Contacts Uploaded', {
            appearance: 'success',
            autoDismiss: true,
        }))
       }

    }
    
    return (
        <div className="d-flex flex-column flex-fill">
            {error && <p>Error</p>}
            {loading && <LoadingSpinner />}
            {invites && 
                <div>
                    <ListGroup variant="flush">
                        <ListGroup.Item className="d-flex flex-row">
                            <Link to='/admin/invites/add'>
                                <Button className="mr-3">Add</Button>
                            </Link>
                            <input type="file" id="files" name="files" onChange={() => handleFileSelect()} ref={inputFile} style={{display: 'none'}}></input>
                            <Button onClick={() => onButtonClick()}>Upload vCard (vcf)</Button>
                        </ListGroup.Item>
                        {invites.docs.map(invite => (
                            <ListGroup.Item key={invite.id}>
                                <Link to={`/admin/invites/${invite.id}`}>
                                    {invite.data().name}
                                </Link>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>
            }
        </div>
    )
}

export default Invites