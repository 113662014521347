import React from "react";
import { Card } from "react-bootstrap";
import firebase from '../../core/firebase'
import ReservationDetails from "../admin/reservation-details";

export interface CalListItemProp {
    reservation: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>;
    onView?: Function;
}

export const CalendarListItem = ({reservation, onView}: CalListItemProp) => {
    return (
        <Card
        onClick={() => !reservation.data().approved && onView ? onView(reservation.id) : '' }
        bg={reservation.data().approved ? '' : 'primary'}
        text={reservation.data().approved ? 'dark' : 'white'}
        className="mb-2"
    >
        <Card.Header>
            {reservation.data().approved ? 'Approved' : 'Pending'}
        </Card.Header>
        <Card.Body>
            <ReservationDetails props={reservation.data()} />
        </Card.Body>
    </Card>
    )
}