import moment from "moment";
import { RequestModel } from "../../types/request";

const ReservationDetails = (props: any) => {
    const request = props.props;
    const DateString = (props: any) => {
        const request = props.data as RequestModel;
        const startDate = moment(request.startDate.toDate());
        const endDate = moment(request.endDate.toDate());
        const days = endDate.diff(startDate, 'days') + 1;
        return (
          <div>
            <p className="mb-1"><strong>When:</strong> {startDate.format("ddd MMM Do 'YY").toString()} to {endDate.format("ddd MMM Do 'YY").toString()}</p>
            <p className="mb-1"><strong>For:</strong> {days} days and {days - 1} nights</p>
          </div>
        )
      }
      
    return (
        <div>
            <p className="mb-1"><strong>From:</strong> {request.name}</p>
            <DateString data={request} />
            <p className="mb-1"><strong>Number of People:</strong> {request.numberPeople}</p>
            <p className="mb-1"><strong>Pets?:</strong> {request.pets ? 'Yes': 'No'}</p>
        </div>
    )
}

export default ReservationDetails