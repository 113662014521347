import firebase from '../core/firebase'
import { useEffect, useRef, useState } from 'react';
import './login.scoped.scss'
import { useHistory } from 'react-router';
import { WelcomeForm } from './welcome-form';


const auth = firebase.auth();
const firestore = firebase.firestore();


function PhoneNumberVerification({ recaptcha }: {recaptcha: firebase.auth.RecaptchaVerifier}) {
   const history = useHistory();
   const [digits, setDigits] = useState('');
   const [invited, setInvited] = useState<boolean | undefined>(undefined);
   const [welcomeNeeded, setWelcomeNeeded] = useState(false);
   const [user, setUser] = useState<firebase.User | null>(null)

   const [confirmationResult, setConfirmationResult] = useState<firebase.auth.ConfirmationResult | null>(null);
   const [code, setCode] = useState('');
 
   const phoneNumber = `+1${digits}`;
 
   // Step 1 - Verify Invite
   useEffect(() => {
     if (phoneNumber.length === 12) {
       const ref = firestore.collection('invites').doc(phoneNumber);
       ref.get().then(({ exists }) => { setInvited(exists) });
     }
   }, [phoneNumber]);

   const signInWithPhoneNumber = async () => {
      setConfirmationResult( await auth.signInWithPhoneNumber(phoneNumber, recaptcha) );
    };
  
    // Step 3 - Verify SMS code
   const verifyCode = async () => {
      if (confirmationResult) {
         const result = await confirmationResult.confirm(code);
         if (result.user) {
            setUser(result.user)
            const userRef = firestore.collection('users').doc(result.user.uid);
            const userDoc = await userRef.get();
            if (userDoc.exists) {
               history.push('/')
            } else {
               setWelcomeNeeded(true)
            }

         }
      }
    };
 
   return (
      <div className="form-signin text-center">
         {!welcomeNeeded &&
            <>
               <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
               <label htmlFor="inputEmail" className="visually-hidden">Phone Number</label>
               <input
                  value={digits}
                  onChange={(e) => setDigits(e.target.value)}
                  type="tel" id="inputEmail"
                  className="form-control"
                  placeholder="Phone Number"
                  required
                  autoFocus
               ></input>
               {!invited && invited !== undefined && 
                  <p className="lead">
                     😕 Hmm... You don't seem to be on the list.  Ask the owners to have your number added.
                  </p>
               }
               {invited === undefined && 
                  <p className="lead">
                     We will then send you a text with a 6 digit code to login with.
                  </p>
               }
               <button className={!invited ? 'hide' : 'w-100 btn btn-lg btn-primary'} onClick={signInWithPhoneNumber}>Send SMS Code</button>
               <fieldset>
                  {confirmationResult && (
                     <>
                        <label htmlFor="inputCode" className="visually-hidden">Verify Code</label>
                        <input
                           value={code}
                           onChange={(e) => setCode(e.target.value)}
                           type="number"
                           id="inputCode"
                           className="form-control"
                           placeholder="------"
                           required
                           autoFocus
                        ></input>
                        <button className="w-100 btn btn-lg btn-primary" onClick={verifyCode}>Verify Code</button>
                     </>
                  )}
               </fieldset>
            </>
         }
         {welcomeNeeded && user && 
            <WelcomeForm user={user}/>
         }
     </div>
   );
 }

export const Login = () => {
   const [recaptcha, setRecaptcha] = useState<firebase.auth.RecaptchaVerifier | null>(null);
   const element = useRef(null);
 
   useEffect(() => {
     if (!recaptcha) {
 
       const verifier = new firebase.auth.RecaptchaVerifier(element.current, {
         size: 'invisible',
       })
 
       verifier.verify().then(() => setRecaptcha(verifier));
 
     }
   });
   return (
      <>
         {recaptcha && <PhoneNumberVerification recaptcha={recaptcha} />}
         <div ref={element}></div>
      </>
   )
}