import React from 'react';
import { Button, Card, CardDeck } from 'react-bootstrap';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { Link } from 'react-router-dom';
import firebase from '../../core/firebase'
import LoadingSpinner from '../../core/loading-spinner';

export const GuestBook = () => {
    const db = firebase.firestore();
    const [messages, loading, error] = useCollectionDataOnce(db.collection('guestBook'))

    return (
        <>
            {loading && <LoadingSpinner />}
            {error && <p>Error</p>}
            {messages && 
                <div className="d-flex flex-column mt-3">
                    <div className="d-flex flex-row justify-content-center mb-1">
                        <div className="d-flex flex-row">
                            <h3>
                                Guest Book
                            </h3>
                            <Link to='/guest-book/add'>
                                
                                <Button className="ml-4">Add Message</Button>
                            </Link>
                            
                        </div>
                    </div>
                    <CardDeck className="p-3">
                    {
                        messages.map((message, i) => (
                            <Card className="p-3 mb-4 min-w-25">
                                <blockquote key={i} className="blockquote mb-0 card-body">
                                <p>
                                    {message.message}
                                </p>
                                <footer className="blockquote-footer">
                                    <small className="text-muted">
                                        {message.fromName}
                                    </small>
                                </footer>
                                </blockquote>
                            </Card>

                        ))
                    }
                    </CardDeck>

                    
                </div>
            }
        </>
    )
}