import { Card, Container } from "react-bootstrap";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import firebase from '../../core/firebase'
import LoadingSpinner from "../../core/loading-spinner";
import { RequestModel } from "../../types/request";
import ReservationDetails from "../admin/reservation-details";

const Reservation = () => {
    const db = firebase.firestore();
    const { id } = useParams<{id: string}>();
    const requestRef = db.doc(`reservations/${id}`)
    const [request, loading, error] = useDocumentDataOnce<RequestModel>(requestRef)
    return (
        <div>
          {loading && <LoadingSpinner/>}
          {error && <p>Error</p>}
          {request && 
          <Container className="pt-4">
            <Card className='mod-card'>
              <Card.Body>
                <Card.Title>
                Reservation
                </Card.Title>
                <ReservationDetails props={request} />
              </Card.Body>
            </Card>
          </Container>

          }
        </div>
    )
}

export default Reservation