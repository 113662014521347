import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyA0XdBAo1dXyI_hBl9opmQAHtCxHg-9EX0",
    authDomain: "bay-cottage.firebaseapp.com",
    projectId: "bay-cottage",
    storageBucket: "bay-cottage.appspot.com",
    messagingSenderId: "480000970419",
    appId: "1:480000970419:web:8eba617724ce793fd02ac6"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}

export default firebase