import React from "react"
import { Button, ListGroup } from "react-bootstrap"
import { useCollection } from "react-firebase-hooks/firestore";
import { Link } from "react-router-dom";
import firebase from '../../core/firebase';
import * as arrayMove from 'array-move';
import './pages.scss'
import LoadingSpinner from "../../core/loading-spinner";

const Pages = () => {
  const db = firebase.firestore();
  const [pages, loading, error] = useCollection(db.collection('book').orderBy('order'))

  const moveUp = (index: number) => {
    if (index !== 0) {
      const idArray = pages?.docs.map(doc => doc.id) || [];
      const newArray = arrayMove.default(idArray, index, index - 1)
      const batch = db.batch();
      newArray.forEach((id, i) => {
        const ref = db.doc(`book/${id}`)
        batch.update(ref, {order: (i+1)})
      });
      batch.commit()
    }
  }

  const moveDown = (index: number) => {
    if (pages) {
      const length = pages?.size - 1 || 0;
      if (index !== length) {
        const idArray = pages?.docs.map(doc => doc.id) || [];
        const newArray = arrayMove.default(idArray, index, index + 1)
        const batch = db.batch();
        newArray.forEach((id, i) => {
          const ref = db.doc(`book/${id}`)
          batch.update(ref, {order: (i+1)})
        });
        batch.commit()
      }
    }
  }

  return (
    <div className="d-flex flex-column flex-fill">

      {error && <p>Error</p>}
      {loading && <LoadingSpinner />}
      {pages &&     
        <div>
          <ListGroup variant="flush">
            {pages.docs.map((doc, i) => (
              <ListGroup.Item key={doc.id} >
                <div className="row pl-2 pr-2">
                  <Link className="pt-2" to={`/admin/book/edit/${doc.id}`}>
                    {doc.data().pageName || ('Page ' + (i + 1))}
                  </Link>
                  <div className="ml-auto">
                    <Button className="mb-0" onClick={() => moveUp(i)}><i className="bi-arrow-up"></i></Button>
                    <Button className="mb-0 ml-1" onClick={() => moveDown(i)}><i className="bi-arrow-down"></i></Button>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
          <div className="pt-3">
            <Link to='/admin/book/edit'>
            <Button block> Add </Button>
            </Link>
          </div>
        </div>
      }

    </div>
  )
}

export default Pages